import axios from 'axios'
import isArray from 'lodash/isArray'
import delay from 'lodash/delay'
import { askSDK } from '@utils/index'
import _trim from 'lodash/trim';
import config from '@/config'

/**
 * 序列化参数
 * @param  {[type]} param [description]
 * @return {[type]}       [description]
 */
export const serializeQuery = function (param = {}, url) {
  const query = []
  // 遍历
  for (const p in param) {
    if ([null, '', undefined].indexOf(param[p]) === -1) {
      query.push(`${encodeURIComponent(p)}=${encodeURIComponent(param[p])}`)
    }
  }
  url = query.length ? `${url}?${query.join('&')}` : url
  return url
}

// 服务端返回异常
const MESSAGE_FROM_SERVER = Symbol('message_from_server')

const configMap = [
  {
    status: 200,
    message: MESSAGE_FROM_SERVER
  },
  {
    status: 401,
    message: '登录认证失败,返回登入页重新登录!(错误码:401)',
    redirection: '/web/#/login'
  },
  {
    status: 403,
    message: '用户权限不够,请联系管理员!(错误码:403）'
  },
  {
    status: 404,
    message: '访问地址不存在,请稍后重试或联系管理员!(错误码:404)'
  },
  {
    status: 415,
    message: '系统运行出错,请稍后重试或联系管理员!(错误码:415)',
    redirection: '/404'
  },
  {
    status: 500,
    message: MESSAGE_FROM_SERVER
  },
  {
    status: 502,
    message: '系统运行出错,请稍后重试或联系管理员!(错误码:502)'
  },
  {
    status: 504,
    message: '系统请求超时,请稍后重试或联系管理员!(错误码:504)'
  },
  {
    status: 0,
    message: '网络出错,请稍后重试或联系管理员!(错误码:0)'
  }
]

// 请求方式
function request(configs) {
  const { url, data = {}, method, params = {}, options = {} } = configs
  // 创建请求id
  const requestId = request.createRequestId()
  // get请求防止缓存
  params.rid = requestId
  // 反序列化本地存储
  const currTenant = JSON.parse(sessionStorage.tenantInfo || '{}')
  // 设置请求请求头中requestId
  options.headers = Object.assign(options.headers || {}, {
    requestId, // 请求id
    tenantId:
      currTenant.tenantId ||
      options?.headers?.tenantId ||
      sessionStorage.tenantId ||
      '',
    userId:
      currTenant.userId ||
      options?.headers?.userId ||
      sessionStorage.userId ||
      '1',
    mainTenaId: currTenant.tenantId || '',
    accoId: sessionStorage.accouserId || currTenant.accouserId || '',
    menuId: sessionStorage.menuId || '',
    token: sessionStorage.token || '',
    os: sessionStorage.os || 'win',
    device: sessionStorage.device || 'smbpc',
    clientip: '192.68.75.235',
    useragent: sessionStorage.useragent || 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.190 Safari/537.36',
    // depaid: permissions.belongDepaId || 1,
    platform: 3 // 后端区分是小b 大b 还是角塑 1-大b；2-小b；3-角塑；
  })

  return axios({
    url,
    data,
    params,
    method,
    ...options
  })
    .then(async (response) => {
      const { status, data: result } = response
      // 成功返回
      if (status >= 200 && status < 400) {
        // 整个返回响应挂载到返回数据中
        result.response = response
        return Promise.resolve(response.data)
      }

      // 非正常返回,获取异常信息
      const expection = configMap.find(con => con.status === status)
      // 当前协议需要提示异常
      if (!options.headers.noErr) {
        // 如果从服务端获取异常 && 是需要提示异常
        request.message(
          `提示: ${expection?.message === MESSAGE_FROM_SERVER
            ? result.message
            : expection?.message
          }`
        )
      }
      // 是否需要重定向
      if (expection?.redirection) {
        // status === 401的时候
        // 判断是手机端还是pc端，手机端调用sdk进行弹窗提示token失效
        // pc端还是进行跳转
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && status === 401) {
          await askSDK('tokenExpire');
          return;
        }
        delay(() => {
          window.location.href = expection.redirection
        }, 3000)
      }

      return Promise.reject(response)
    })
    .then(result => {
      // 状态 status >= 200 && status < 400 处理
      // 如果是状态000直接返回
      if (result.status === '000') {
        return result.data
      } else if (result instanceof Blob) {
        return result
      }
      return Promise.reject(result)
    })
    .catch(error => {
      return Promise.reject(error)
    })
    .finally(() => { })
}

// 请求停止
request.CancelToken = axios.CancelToken
request.isCancel = axios.isCancel
// 异常不提示
request.NO_ERR = ':no-err'

request.delete = () => { }
request.get = () => { }
request.head = () => { }
request.options = () => { }
request.post = () => { }
request.put = () => { }
request.patch = () => { }

/**
 * 创建requestId请求头
 */
request.createRequestId = () => {
  // 生成时间戳
  const stamp = String(new Date().getTime())
  // 生成随机数
  const r = String(Math.floor(90000 * Math.random() + 10000))

  return stamp + r
}

/**
 * 过滤参数
 */
request.filter = (data = {}) => {
  if (isArray(data)) return data
  // 过滤掉 ''，undeinfed,null等值
  const result = {}

  for (const [k, v] of Object.entries(data)) {
    if (['', undefined, null].indexOf(v) === -1) {
      result[k] = typeof v === 'string' ? _trim(v) : v
    }
  }

  return result
}

  // 各种方法
  ;['delete', 'get', 'head', 'options'].forEach(method => {
    request[method] = function (url, params, options = {}) {
      // 过滤参数
      // params = request.filter(params);
      // 发送器请求
      return request({
        url,
        params,
        method,
        options
      })
    }
  })
  ;['post', 'put', 'patch'].forEach(method => {
    request[method] = function (url, data = {}, options = {}) {
      // 计算hashcode防止双击

      // 过滤参数
      data = request.filter(data)
      // 发送请求
      return request({
        url,
        data,
        method,
        options
      })
    }
  })

request.blob = (url, data = {}, options = {}) => {
  const { method = 'get', ...rest } = options
  // 过滤参数
  data = request.filter(data)
  // 设置响应头
  rest.responseType = 'blob'
  // 发送请求
  return request[method](url, data, rest)
}

request.message = function () { }

// 默认请求前缀
axios.defaults.baseURL = config?.baseURL || '/api'
// 默认全局请求编码
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.patch['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
// 默认全局超时时间
axios.defaults.timemout = 5000
// 去掉状态状态校验
axios.defaults.validateStatus = false

export default request
