import react, { useEffect } from 'react'
import { Layout, Menu, Breadcrumb } from 'antd'
import './index.less'
import HeadLogo from '../../../../static/image/headlogo.png'
import Crumb from '../Crumb'

const { Header, Content, Footer } = Layout

export default function BaseLayout(props) {
  useEffect(() => {
    // 如果当前用户没有token直接返回登陆页面
    if (!sessionStorage.token) {
      window.location.href = '/web/#/login';
    }
  }, []);
  return (
    <Layout className="layout">
      <Header className="header">
        <div className="header_wrap">
          <img className="logo" src={HeadLogo} />
        </div>
      </Header>
      <Content className="content">
        <Crumb />
        {/* <Breadcrumb style={{margin: '16px 0'}}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="site-layout-content">{props.children}</div>
      </Content>
      <Footer className="footer">
        <div>杭州贯通云科技有限公司运营服务</div>
        <div>
          Copyright © 2017-2020 best-seeing.com 版权所有 浙B2-20090104-5
          浙公网安备 33010802009257号
        </div>
      </Footer>
    </Layout>
  )
}
