import dsBridge from 'dsbridge'
/**
 * 获取 querystring name 对应的值
 * @param  {string} name key
 * @return {string}      value
 */
export function getQueryString(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  // const search = window.location.hash
  const search = window.location.hash.match(/\?[\w&=%-.]*/g)

  if (!search) return null
  const r = search[0].substr(1).match(reg)

  // const r = search.match(reg)
  if (r != null) {
    return decodeURI(r[2])
  }
  return null
}

/**
 * js首字母大写
 * @param {Object} str
 */
export function toUpperCaseHeader(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 *
 *调用SDk
 * @export
 * @param {*} name
 * @param {*} callBack
 */
export async function askSDK(name, data, callBack = () => {}) {
  let u = navigator.userAgent
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
  if (isAndroid) {
    await dsBridge.call(name, data, callBack)
  } else {
    window.nativeApi && (await window.nativeApi[`${name}`](data, callBack))
  }
}
/**
 *
 *
 * @export
 * @param {*} value
 * @param {*} num // 保留几位
 */
export function returnFloat(value, num) {
  if (value) {
    num = num ? parseInt(num) : 0
    if (num <= 0) {
      return Math.round(value)
    }
    value = Math.round(value * Math.pow(10, num)) / Math.pow(10, num) //四舍五入
    value = Number(value).toFixed(num) //补足位数
    return value
  }
}
/**
 * 简单发布订阅
 * @param {Object}} callback
 * @returns
 */
export const Observable = {
  queue: {},

  subscribe: function (event, callback) {
    if (!this.queue[event]) {
      this.queue[event] = []
    }
    this.queue[event].push(callback)

    return () => {
      this.queue[event] = null
    }
  },

  publish: function (event, options = {}) {
    ;(this.queue[event] || []).forEach(callback => {
      callback.call(null, options)
    })
  }
}
export  function debounce(fn, delay) {
  var timer = null;
  return function (e) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      return fn(e)
    }, delay);
    return timer;
  }

}