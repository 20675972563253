import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunkMiddleware from 'redux-thunk'
import commonReducer from './common/reducer.js'
import userReducer from './user/reducer.js'

const rootReducer = combineReducers({
  common: commonReducer,
  user: userReducer
})

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

export default store
