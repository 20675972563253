import actionTypes from './actionTypes'

let initialState = {
  bread: {
    keys: ['881050000000', '881050030000'],
    name: '首页',
    path: '/home',
    secondly: { title: '促销折扣', path: '/auth' },
    thirdly: {
      path: '/agency',
      title: '新建活动'
    }
  }
}
// 如果存储里有面包屑的信息则去session里的，否则就默认的
if (sessionStorage.bread) {
  initialState = JSON.parse(sessionStorage.bread)
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATEBREAD:
      const bread = { ...state.bread, ...action.payload }
      sessionStorage.bread = JSON.stringify(bread)
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default reducer
