import React from 'react'

const WithLazyLoad = WrappedComponent =>
  class HOC extends React.Component {
    render() {
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <WrappedComponent {...this.props} />
        </React.Suspense>
      )
    }
  }

export default WithLazyLoad
