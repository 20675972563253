// 运行时配置
const baseConfig = {
  iconScriptUrl: 'https://at.alicdn.com/t/c/font_2782203_juf19fvbgy.js', // 自定义iconfont地址
  printBaseURL: (function () {
    // 判断IE
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      // 打印机地址配置
      return 'https://appweb.best-seeing.cn:38786';
    } else {
      // 打印机地址配置
      return 'http://127.0.0.1:38785';
    }
  })(),
};
window.OSS_URI = (function () {
  // const host = window.location.host
  // if (/^test\./.test(host)) {
  //   return '//bs-read.best-seeing.cn';
  // } else if (/^access\./.test(host)) {
  //   return '//mpub.best-seeing.com';
  // }
  return '//mpub.best-seeing.com';
})();

const config = {
  // 默认配置
  default: {
    ...baseConfig,
    baseURL: '/api',
  },
  // 开发配置
  local: {
    ...baseConfig,
    baseURL: 'https://ortho-k-delta.best-seeing.com/api',
  },
  // 开发delta环境配置
  delta: {
    // 开发20环境
    ...baseConfig,
    baseURL: 'https://ortho-k-delta.best-seeing.com/api',
  },
  deltas: {
    // 开发20环境
    ...baseConfig,
    baseURL: 'https://ortho-k-delta.best-seeing.com/api',
  },
  beta: {
    // 开发20环境
    ...baseConfig,
    // baseURL: 'https://ortho-k-beta.best-seeing.com/api',
    baseURL: 'https://ortho-k70.best-seeing.cn/api', // https://ortho-k70.best-seeing.cn/api
  },
  // 开发alpha环境配置
  alpha: {
    // 开发20环境
    ...baseConfig,
    baseURL: 'https://access-alpha.best-seeing.com/api',
  },

  // 开发35环境配置
  s35: {
    // 开发70环境
    ...baseConfig,
    // baseURL: 'http://192.68.75.35:90/api'
    baseURL: 'http://192.168.50.70:1001/api',
  },
  // 测试36环境配置
  s36: {
    ...baseConfig,
    // baseURL: 'http://192.68.75.36:88/api',
    baseURL: 'https://ortho-k36.best-seeing.cn/api',
  },
  // 测试26环境配置
  s26: {
    ...baseConfig,
    baseURL: 'http://192.68.75.26:88/api',
  },
  // 测试27环境配置
  s27: {
    ...baseConfig,
    baseURL: 'http://192.68.75.27:88/api',
  },
  // 测试37环境配置
  s37: {
    ...baseConfig,
    baseURL: 'http://192.68.75.37:88/api',
  },
  // 测试41环境配置
  s41: {
    ...baseConfig,
    baseURL: 'http://192.68.75.41:88/api',
  },
  // 测试245环境配置
  s245: {
    ...baseConfig,
    baseURL: 'https://smb-epsilon.best-seeing.com/api',
  },
  // 测试245环境配置
  s215: {
    ...baseConfig,
    baseURL: 'https://access-beta.best-seeing.com/api',
  },
  // 测试245环境配置
  s235: {
    ...baseConfig,
    baseURL: 'https://access-delta.best-seeing.com/api',
  },
  // 生产环境配置
  prod: {
    ...baseConfig,
    baseURL: '/api',
  },
  pub: {
    // 预发布代理
    ...baseConfig,
    baseURL: 'https://ortho-k.best-seeing.cn/api',
  },
  pro: {
    // 生产代理
    ...baseConfig,
    // baseURL: '/proxy',
    baseURL: 'https://eyes.best-seeing.com/api',
  },
};

function get(host) {
  console.log('host', host);
  return config[host];
}

export default get(process.env.HOST_NAME);
