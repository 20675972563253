import React from 'react';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import {message, ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import Layout from '@web/components/Layout';
import request from '@utils/request';
import WithLazyLoad from '@web/components/WithLazyLoad';
import {Provider} from 'react-redux';
import store from './store/index.js';
import router from './route.js';
import './global.less';
/**
 * antd新版本中的坑 中文语言包中的年月日星期翻译被去掉了
 * moment中提供了年月日翻译的语言包
 * 解决方案引入下面三行代码即可
 *
 *
 * */
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

request.message = function (content) {
  message.error(content, 5);
};

// 懒加载引入
const lazyImp = (path) => {
  return React.lazy(() => import(`@web/${path}`));
};

const Home = lazyImp('Home');
const Login = lazyImp('Login');

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <Router basename="/">
          <Switch>
            <Route exact path="/login" component={WithLazyLoad(Login)} />
            <Route exact path="/home" component={WithLazyLoad(Home)} />
            <Layout>
              {router.map((val) => {
                const lazyCom = lazyImp(val.importPath);
                const component = WithLazyLoad(lazyCom);
                return (
                  <Route
                    path={val.path}
                    exact={val.exact || true}
                    component={component}
                  />
                );
              })}
            </Layout>
          </Switch>
        </Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
