import React from 'react'
import {Breadcrumb} from 'antd'
import {LeftOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router'
import {HashRouter, Link} from 'react-router-dom'
import './index.less'
import {useSelector, useDispatch} from 'react-redux'
import {updateBread} from '../../store/user/action'
import store from '../../store/index'
let lastLength = 0
let crrentBread = store.getState().user.bread // 当前面包屑
const hashMap = new Map() // 历史记录集合
console.log(store.getState())
/**
 * history.length改变的时候将hash存入store
 *  不变的时候取hash取store中的存入的hash
 * */
// window.addEventListener('popstate', (event) => {
//   const hash = window.location.hash
//   const history = window.history

//   // console.log(window)
//   if (history.length === lastLength) {
//     // 前进或后退
//     console.log(hashMap.get(hash))
//     const current = hashMap.get(hash)
//     if (current) {
//       store.dispatch(updateBread({bread: hashMap.get(hash)}))
//     }
//   } else {
//     setTimeout(() => {
//       console.log(crrentBread)
//       hashMap.set(hash, crrentBread)
//     })
//   }
//   lastLength = history.length
// })

function Crumb() {
  const user = useSelector((state) => state.user)
  const {bread = {}} = user
  const {secondly = {}, thirdly = {}} = bread
  const dispatch = useDispatch()
  const history = useHistory()
  const result = []
  // 判断二级是否需要显示
  if (secondly.title) result.push(secondly)
  // 判断三级是否需要显示
  if (thirdly.title) result.push(thirdly)

  // crrentBread = bread

  function goBack() {
    // if (thirdly.title) {
    //   let path = thirdly.path
    //   bread.thirdly = {}
    //   dispatch(updateBread({...bread}))
    //   history.push(path)
    //   return
    //   // history.goBack()
    // }
    history.push('/home')
    // if (secondly.title) {
    //   let path = thirdly.path
    //   bread.secondly = {}
    //   dispatch(updateBread({...bread}))
    //   history.push(path)
    //   return
    //   //
    // }
  }

  /*
   * 渲染头部面包屑的name
   *
   */
  function renderName() {
    // const routerList = ['/usercenter/account', '/usercenter/message']
    // const routerTitles = ['账户信息', '系统消息']
    // const index = routerList.indexOf(history.location.pathname)

    // if (index > -1) {
    //   return (
    //     <Link onClick={goBack} className={styles.icon_row}>
    //       <font className={secondly.title ? styles.first : styles.other}>
    //         {routerTitles[index]}
    //       </font>
    //     </Link>
    //   )
    // }

    return (
      <Link className="icon_row" to={bread.path}>
        {/* {secondly.title ? (
          <LeftOutlined size="large" className={styles.icon_back} />
        ) : (
          <Icon type={bread.icon} className={styles.icon} size="large" />
        )} */}
        <font className={`${secondly.title ? 'first' : 'first_light'}`}>
          {bread.name}
        </font>
      </Link>
    )
  }

  return (
    <HashRouter>
      <div className="crumb">
        <Breadcrumb separator="/">
          <Breadcrumb.Item onClick={() => goBack()}>
            {renderName()}
          </Breadcrumb.Item>
          {/* 显示二三级面包屑 */}
          {result.map((item) => {
            return (
              <Breadcrumb.Item key={item.title}>
                <span className="other_light">{item.title}</span>
              </Breadcrumb.Item>
            )
          })}
        </Breadcrumb>
      </div>
    </HashRouter>
  )
}

export default Crumb
