import actionTypes from './actionTypes'

const initialState = {
  list: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVELIST:
      return {...state, ...action.Payload}
    default:
      return state
  }
}

export default reducer
