/**
 * 路由引入
 * {
 *   path:'' // 路由地址
 *   importPath: '' // 组件对应文件路径 默认从 web文件夹下开始
 *   exact: true // 是否完全匹配 默认true
 *   name:'' // 路由对应页面名称 标识哪个页面
 * }
 * */
const router = [
  {
    name: '医生授权',
    path: '/auth',
    importPath: 'Auth',
    exact: true,
  },
  {
    name: '机构信息维护',
    path: '/agency',
    importPath: 'Agency',
  },
  {
    name: '知情同意书',
    path: '/consent',
    importPath: 'Consent',
  },
  {
    name: '镜片库维护',
    path: '/glasses',
    importPath: 'Glasses',
  },
  {
    name: '试戴片管理',
    path: '/glasses/wearglasses/:id',
    importPath: 'Glasses/WearGlasses',
  },
  {
    name: '患者',
    path: '/patient',
    importPath: 'Patient',
  },
  {
    name: '历史病历导入',
    path: '/historymedical',
    importPath: 'HistoryMedical',
  },
  {
    name: '历史病历导入记录',
    path: '/historymedical/record',
    importPath: 'HistoryMedical/HistoryRecord',
  },
  {
    name: '病历补录',
    path: '/historymedical/detail/:id',
    importPath: 'HistoryMedical/createOrEdit.js',
  },
  {
    name: '病例配置',
    path: '/caseconfiguration/:type',
    exact: false,
    importPath: 'CaseConfiguration',
  },
  {
    name: '处方关联',
    path: '/prescriptionassociation',
    importPath: 'PrescriptionAssociation',
  },
]

export default router
